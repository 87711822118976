//London and whipsnade colours are placeholders
//TODO please change them
import { colors as baseColors } from '@/theme/lib';

export const colors = {
  zsl: {
    primary: baseColors.seagrass,
    secondary: baseColors.zooBg,
    tertiary: baseColors.parakeet,
    eyebrow: baseColors.honeyBee,
    infoLabel: baseColors.honeyBee,
    background: baseColors.panther,
    testimonial: {
      title: baseColors.zooBg,
      quote: {
        text: baseColors.white,
        quoteIcon: baseColors.gecko,
        background: baseColors.seagrass,
      },
      slider: {
        mainTitleText: baseColors.zooBg,
        paginationBulletActive: baseColors.parakeet,
        paginationBullet: baseColors.parakeet,
        paginationArrowCircle: baseColors.seagrass,
        paginationArrowPath: baseColors.parakeet,
      },
    },
    button: {
      primary: {
        text: baseColors.white,
        bg: baseColors.seagrass,
        border: 'transparent',
        focusBorder: baseColors.parakeet,
      },
      secondary: {
        text: baseColors.seagrass,
        bg: baseColors.white,
        border: baseColors.seagrass,
        focusBorder: baseColors.parakeet,
      },
      download: {
        text: baseColors.seagrass,
        bg: baseColors.white,
        border: baseColors.seagrass,
        focusBorder: baseColors.parakeet,
        icon: baseColors.seagrass,
        file: 'london',
      },
      news: {
        text: baseColors.seagrass,
        bg: baseColors.white,
        border: baseColors.white,
        focusBorder: baseColors.parakeet,
      },
      pill: {
        text: baseColors.white,
        bg: baseColors.seagrass,
        border: 'transparent',
        focusBorder: baseColors.parakeet,
      },
      social: {
        bg: baseColors.parakeet,
        border: baseColors.parakeet,
        focusBorder: baseColors.seagrass,
      },
    },
    cards: {
      infographic: {
        color: baseColors.panther,
        background: baseColors.parakeet,
      },
      generic: {
        link: baseColors.parakeet,
        eyebrow: baseColors.honeyBee,
        paragraph: baseColors.zooBg,
        imageBackground: baseColors.parakeet,
        label: baseColors.honeyBee,
      },
      featureCardTwoThreeSection: {
        background: baseColors.white,
        pattern: baseColors.white,
        primaryButtonBg: baseColors.seagrass,
        primaryButtonText: baseColors.white,
        secondaryButtonHoverBorderColor: baseColors.parakeet,
        primaryButtonHoverBorderColor: baseColors.parakeet,
        secondaryButtonBg: baseColors.white,
        secondaryButtonText: baseColors.seagrass,
        mainTitle: baseColors.panther,
        cardTitle: baseColors.panther,
        cardEyebrow: baseColors.parakeet,
        cardParagraph: baseColors.panther,
      },
      event: {
        text: baseColors.white,
        border: baseColors.parakeet,
        background: 'transparent',
        ctaText: baseColors.panther,
        ctaBg: baseColors.parakeet,
      },
    },
    carousel: {
      mainTitleText: baseColors.zooBg,
      paginationBulletActive: baseColors.parakeet,
      paginationBullet: baseColors.seagrassDark,
      paginationOverviewAnchorBg: baseColors.seagrass,
      paginationOverviewAnchorText: baseColors.white,
      paginationArrowCircle: baseColors.seagrass,
      paginationArrowPath: baseColors.parakeet,
    },
    promo: {
      withBackground: {
        text: baseColors.white,
        button: baseColors.white,
      },
      withoutBackground: {
        text: baseColors.white,
        button: baseColors.seagrass,
      },
    },
    donate: {
      background: baseColors.seagrass,
      text: baseColors.white,
      link: {
        background: baseColors.parakeet,
        text: baseColors.panther,
      },
      button: {
        background: baseColors.panther,
        text: baseColors.white,
        activeBackground: baseColors.parakeet,
        hoverText: baseColors.parakeet,
      },
    },
    donateHeaderButton: {
      background: baseColors.parakeet,
      text: baseColors.panther,
      activeBackground: baseColors.white,
      activeText: baseColors.seagrass,
      hoverBackground: baseColors.panther,
      hoverText: baseColors.parakeet,
      border: baseColors.panther,
    },
    newsletter: {
      bg: baseColors.seagrass,
    },
    featureBackground: baseColors.seagrass,
    caption: baseColors.white,
    heading: baseColors.zooBg,
    text: baseColors.zooBg,
    tableHeading: baseColors.white,
    link: baseColors.parakeet,
    accordionTabColour: baseColors.white,
    accordionIconColour: baseColors.parakeet,
    accordionTabBgr: baseColors.seagrass,
    accordionTabBorderColour: baseColors.gecko,
    accordionTabFocusColour: baseColors.parakeet,
    accordionTabLink: baseColors.parakeet,
    footerBgr: baseColors.seagrass,
    footerNewsletterBlockBtnBgr: baseColors.seagrass,
    footerNewsletterBlockBtnColor: baseColors.white,
    footerNewsletterBlockTextColor: baseColors.seagrass,
    dateUnderlineColor: baseColors.parakeet,
    membership: {
      type: {
        goldColor: baseColors.honeyBee,
        silverColor: baseColors.silver,
        nameColor: baseColors.white,
        descriptionColor: baseColors.honeyBee,
        borderColor: baseColors.parakeet,
      },
      price: {
        backgroundColor: baseColors.seagrass,
        pawsIconColor: {
          primary: baseColors.black,
          secondary: baseColors.white,
        },
      },
    },
    eventListing: {
      backgroundColor: baseColors.seagrass,
    },
  },
  london: {
    primary: baseColors.gecko,
    secondary: baseColors.zooFont,
    tertiary: baseColors.salmon,
    eyebrow: baseColors.seagrass,
    infoLabel: baseColors.black,
    background: baseColors.londonBg,
    testimonial: {
      title: baseColors.zooFont,
      quote: {
        text: baseColors.white,
        quoteIcon: baseColors.honeyBee,
        background: baseColors.seagrass,
      },
      slider: {
        mainTitleText: baseColors.zooFont,
        paginationBulletActive: baseColors.gecko,
        paginationBullet: baseColors.gecko,
        paginationArrowCircle: baseColors.honeyBee,
        paginationArrowPath: baseColors.black,
      },
    },
    button: {
      primary: {
        text: baseColors.black,
        bg: baseColors.gecko,
        border: 'transparent',
        focusBorder: baseColors.honeyBee,
      },
      secondary: {
        text: baseColors.black,
        bg: baseColors.white,
        border: baseColors.gecko,
        focusBorder: baseColors.honeyBee,
      },
      download: {
        text: baseColors.black,
        bg: baseColors.gecko,
        border: 'transparent',
        focusBorder: baseColors.honeyBee,
        icon: baseColors.white,
        file: 'london',
      },
      news: {
        text: baseColors.black,
        bg: baseColors.honeyBee,
        border: baseColors.honeyBee,
        focusBorder: baseColors.gecko,
      },
      pill: {
        text: baseColors.black,
        bg: baseColors.honeyBee,
        border: baseColors.honeyBee,
        focusBorder: baseColors.gecko,
      },
      social: {
        bg: baseColors.gecko,
        border: baseColors.gecko,
        focusBorder: baseColors.honeyBee,
      },
    },
    cards: {
      infographic: {
        color: baseColors.zooFont,
        background: baseColors.gecko,
      },
      generic: {
        link: baseColors.seagrass,
        eyebrow: baseColors.zooFont,
        paragraph: baseColors.zooFont,
        imageBackground: baseColors.seagrass,
        label: baseColors.seagrass,
      },
      featureCardTwoThreeSection: {
        background: baseColors.seagrass,
        pattern: baseColors.honeyBee,
        primaryButtonBg: baseColors.honeyBee,
        primaryButtonText: baseColors.zooFont,
        secondaryButtonHoverBorderColor: baseColors.honeyBee,
        primaryButtonHoverBorderColor: baseColors.gecko,
        secondaryButtonBg: baseColors.white,
        secondaryButtonText: baseColors.zooFont,
        mainTitle: baseColors.zooBg,
        cardTitle: baseColors.white,
        cardEyebrow: baseColors.white,
        cardParagraph: baseColors.white,
      },
      event: {
        text: baseColors.zooFont,
        border: baseColors.parakeet,
        background: baseColors.londonBg,
        ctaText: baseColors.black,
        ctaBg: baseColors.gecko,
      },
    },
    carousel: {
      mainTitleText: baseColors.zooFont,
      paginationBulletActive: baseColors.seagrass,
      paginationBullet: baseColors.seagrass,
      paginationOverviewAnchorBg: baseColors.seagrass,
      paginationOverviewAnchorText: baseColors.zooBg,
      paginationArrowCircle: baseColors.honeyBee,
      paginationArrowPath: baseColors.black,
    },
    promo: {
      withBackground: {
        text: baseColors.white,
        button: baseColors.zooFont,
      },
      withoutBackground: {
        text: baseColors.zooFont,
        button: baseColors.black,
      },
    },
    donate: {
      background: baseColors.seagrass,
      text: baseColors.white,
      link: {
        background: baseColors.parakeet,
        text: baseColors.panther,
      },
      button: {
        background: baseColors.panther,
        text: baseColors.white,
        activeBackground: baseColors.parakeet,
        hoverText: baseColors.parakeet,
      },
    },
    donateHeaderButton: {
        background: baseColors.parakeet,
        text: baseColors.panther,
        activeBackground: baseColors.white,
        activeText: baseColors.seagrass,
        hoverBackground: baseColors.panther,
        hoverText: baseColors.parakeet,
        border: baseColors.panther,
    },
    newsletter: {
      bg: baseColors.seagrass,
    },
    featureBackground: baseColors.seagrass,
    caption: baseColors.zooFont,
    heading: baseColors.zooFont,
    text: baseColors.zooFont,
    tableHeading: baseColors.gecko,
    link: baseColors.seagrass,
    accordionTabColour: baseColors.zooFont,
    accordionIconColour: baseColors.zooFont,
    accordionTabBgr: baseColors.gecko,
    accordionTabBorderColour: baseColors.gecko,
    accordionTabFocusColour: baseColors.honeyBee,
    accordionTabLink: baseColors.seagrass,
    footerBgr: baseColors.seagrass,
    footerNewsletterBlockBtnBgr: baseColors.honeyBee,
    footerNewsletterBlockBtnColor: baseColors.panther,
    footerNewsletterBlockTextColor: baseColors.zooFont,
    dateUnderlineColor: baseColors.gecko,
    membership: {
      type: {
        goldColor: baseColors.honeyBee,
        silverColor: baseColors.silver,
        nameColor: baseColors.black,
        descriptionColor: baseColors.seagrass,
        borderColor: baseColors.gecko,
      },
      price: {
        backgroundColor: baseColors.seagrass,
        pawsIconColor: {
          primary: baseColors.seagrass,
          secondary: baseColors.honeyBee,
        },
      },
    },
    eventListing: {
      backgroundColor: baseColors.seagrass,
    },
  },
  whipsnade: {
    primary: baseColors.tiger,
    secondary: baseColors.parakeet,
    tertiary: baseColors.black,
    eyebrow: baseColors.redPanda,
    infoLabel: baseColors.black,
    background: baseColors.whipsnadeBg,
    testimonial: {
      title: baseColors.zooFont,
      quote: {
        text: baseColors.white,
        quoteIcon: baseColors.honeyBee,
        background: baseColors.redPanda,
      },
      slider: {
        mainTitleText: baseColors.zooFont,
        paginationBulletActive: baseColors.tiger,
        paginationBullet: baseColors.tiger,
        paginationArrowCircle: baseColors.honeyBee,
        paginationArrowPath: baseColors.black,
      },
    },
    button: {
      primary: {
        text: baseColors.black,
        bg: baseColors.tiger,
        border: 'transparent',
        focusBorder: baseColors.honeyBee,
      },
      secondary: {
        text: baseColors.black,
        bg: baseColors.white,
        border: baseColors.tiger,
        focusBorder: baseColors.honeyBee,
      },
      download: {
        text: baseColors.black,
        bg: baseColors.tiger,
        border: 'transparent',
        focusBorder: baseColors.honeyBee,
        icon: baseColors.white,
        file: 'whipsnade',
      },
      news: {
        text: baseColors.black,
        bg: baseColors.honeyBee,
        border: baseColors.honeyBee,
        focusBorder: baseColors.tiger,
      },
      pill: {
        text: baseColors.black,
        bg: baseColors.honeyBee,
        border: baseColors.honeyBee,
        focusBorder: baseColors.tiger,
      },
      social: {
        bg: baseColors.tiger,
        border: baseColors.tiger,
        focusBorder: baseColors.honeyBee,
      },
    },
    cards: {
      infographic: {
        color: baseColors.zooFont,
        background: baseColors.tiger,
      },
      generic: {
        link: baseColors.redPanda,
        eyebrow: baseColors.zooFont,
        paragraph: baseColors.zooFont,
        imageBackground: baseColors.redPanda,
        label: baseColors.redPanda,
      },
      featureCardTwoThreeSection: {
        background: baseColors.redPanda,
        pattern: baseColors.honeyBee,
        primaryButtonBg: baseColors.honeyBee,
        primaryButtonText: baseColors.zooFont,
        secondaryButtonHoverBorderColor: baseColors.honeyBee,
        primaryButtonHoverBorderColor: baseColors.tiger,
        secondaryButtonBg: baseColors.white,
        secondaryButtonText: baseColors.zooFont,
        mainTitle: baseColors.zooBg,
        cardTitle: baseColors.white,
        cardEyebrow: baseColors.white,
        cardParagraph: baseColors.white,
      },
      event: {
        text: baseColors.zooFont,
        border: baseColors.whipsnadeBg,
        background: baseColors.whipsnadeBg,
        ctaText: baseColors.panther,
        ctaBg: baseColors.tiger,
      },
    },
    carousel: {
      mainTitleText: baseColors.zooFont,
      paginationBulletActive: baseColors.tiger,
      paginationBullet: baseColors.tiger,
      paginationOverviewAnchorBg: baseColors.redPanda,
      paginationOverviewAnchorText: baseColors.zooBg,
      paginationArrowCircle: baseColors.honeyBee,
      paginationArrowPath: baseColors.zooFont,
    },
    promo: {
      withBackground: {
        text: baseColors.white,
        button: baseColors.zooFont,
      },
      withoutBackground: {
        text: baseColors.zooFont,
        button: baseColors.black,
      },
    },
    donate: {
      background: baseColors.seagrass,
      text: baseColors.white,
      link: {
        background: baseColors.parakeet,
        text: baseColors.panther,
      },
      button: {
        background: baseColors.panther,
        text: baseColors.white,
        activeBackground: baseColors.parakeet,
        hoverText: baseColors.parakeet,
      },
    },
    donateHeaderButton: {
      background: baseColors.parakeet,
      text: baseColors.panther,
      activeBackground: baseColors.white,
      activeText: baseColors.seagrass,
      hoverBackground: baseColors.panther,
      hoverText: baseColors.parakeet,
      border: baseColors.panther,
    },
    newsletter: {
      bg: baseColors.redPanda,
    },
    featureBackground: baseColors.redPanda,
    caption: baseColors.zooFont,
    heading: baseColors.zooFont,
    text: baseColors.zooFont,
    tableHeading: baseColors.tiger,
    link: baseColors.redPanda,
    accordionTabColour: baseColors.zooFont,
    accordionIconColour: baseColors.zooFont,
    accordionTabBgr: baseColors.tiger,
    accordionTabBorderColour: baseColors.tiger,
    accordionTabFocusColour: baseColors.honeyBee,
    accordionTabLink: baseColors.redPanda,
    footerBgr: baseColors.redPanda,
    footerNewsletterBlockBtnBgr: baseColors.honeyBee,
    footerNewsletterBlockBtnColor: baseColors.panther,
    footerNewsletterBlockTextColor: baseColors.zooFont,
    dateUnderlineColor: baseColors.tiger,
    membership: {
      type: {
        goldColor: baseColors.honeyBee,
        silverColor: baseColors.white,
        nameColor: baseColors.black,
        descriptionColor: baseColors.redPanda,
        borderColor: baseColors.tiger,
      },
      price: {
        backgroundColor: baseColors.redPanda,
        pawsIconColor: {
          primary: baseColors.tiger,
          secondary: baseColors.honeyBee,
        },
      },
    },
    eventListing: {
      backgroundColor: baseColors.redPanda,
    },
  },
};
