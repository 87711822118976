import {gql} from "@apollo/client";
import WYSIWYGFragment from "../WYSIWYG/WYSIWYG.fragment";
import ImpactBlockFragment from "../ImpactBlock/ImpactBlock.fragment";
import PromoBlockFragment from "../PromoBlock/PromoBlock.fragment";
import AccordionFragment from "../Accordion/Accordion.fragment";
import NewsletterSignUpBlockFragment from "../NewsletterSignUpBlock/NewsletterSignUpBlock.fragment";
import InfographicFragment from "@components/Infographic/Infographic.fragment";
import ProjectInDetailSliderFragment from "@components/ProjectInDetailSlider/ProjectInDetailSlider.fragment";
import FeatureCardFragment from "@components/FeatureCard/FeatureCard.fragment";
import CarouselFragment from "@components/Carousel/Carousel.fragment";
import DownloadFragment from "@components/DownloadButtons/DownloadButtons.fragment"
import SpeciesFragment from "@components/SpeciesBlock/SpeciesBlock.fragment"
import ImagesFragment from "@components/Images/Images.fragment"
import GeneralListingFragment from "@components/GeneralListing/GeneralListing.fragment";
import ImageGalleryFragment from "@components/ImageGallery/ImageGallery.fragment";
import WebformFragment from "@components/Webform/Webform.fragment";
import InPageTabsFragment from "@components/InPageTabs/InPageTabs.fragment";
import FourPillarsFragment from "@components/FourPillars/FourPillars.fragment";
import TodayAtTheZooFragment from "@components/TodayAtZooListing/TodayAtZooListing.fragment";
import InfoBlockFragment from "@components/InfoBlock/InfoBlock.fragment";
import FeatureCardVariant from "@components/FeatureCardVariant/FeatureCardVariant.fragment";
import TestimonialBlockQuoteFragment from "../TestimonialBlockQuote/TestimonialBlockQuote.fragment";
import EventListingFragment from '../EventListing/EventListing.fragment';

const fragment = gql`
  ${WYSIWYGFragment}
  ${ImpactBlockFragment}
  ${PromoBlockFragment}
  ${AccordionFragment}
  ${InfographicFragment}
  ${ProjectInDetailSliderFragment}
  ${NewsletterSignUpBlockFragment}
  ${FeatureCardFragment}
  ${CarouselFragment}
  ${DownloadFragment}
  ${SpeciesFragment}
  ${ImagesFragment}
  ${GeneralListingFragment}
  ${ImageGalleryFragment}
  ${WebformFragment}
  ${InPageTabsFragment}
  ${FourPillarsFragment}
  ${TodayAtTheZooFragment}
  ${InfoBlockFragment}
  ${FeatureCardVariant}
  ${TestimonialBlockQuoteFragment}
  ${EventListingFragment}
  fragment Paragraph on Paragraph {
    ...WYSIWYGFragment
    ...ImpactBlockFragment
    ...PromoBlockFragment
    ...AccordionFragment
    ...InfographicFragment
    ...ProjectInDetailSliderFragment
    ...NewsletterSignUpBlockFragment
    ...FeatureCardFragment
    ...CarouselFragment
    ...DownloadFragment
    ...SpeciesFragment
    ...ImagesFragment
    ...GeneralListingFragment
    ...ImageGalleryFragment
    ...WebformFragment
    ...InPageTabsFragment
    ...FourPillarsFragment
    ...TodayAtTheZooFragment
    ...InfoBlockFragment
    ...FeatureCardVariant
    ...TestimonialsFragment
    ...EventListingFragment
  }
`;

export default fragment;
