import { getCmsHostname, getFrontendHostname } from "@lib/config";

const cspScriptHashes = (): string => {
  // Any CSP hashes that need to be included. For example GTM-inserted scripts.
  return `
  'sha256-ZnhAS6LXLZWmpPSEDrVRkNeIsVEIsRredGkFx2WiKec='
  'sha256-iW4WnuBtxrtQK6YASZph6rx6yZZMg0U77OLIlEZvlDA='
  'sha256-48TuDkUabuH2v1zRqgyqecgn+HRkc9gfpOVMELfgzQI='
  'sha256-U96gVTGDtlmnaK204EbaOTNyZRKvfBiWyuolgJwd8CI='
  'sha256-2L/tFj1yFovfnD4TertAGuww56zrGsGC1JDWaqC75s0='
  'sha256-9HOlWcnxwIXUmTPdnAhre+W8Z0FDeSQmn4+a1GXnQSA='
  'sha256-dKGt8dCuqKR/hwtTSFNbDeXeYUxOjtop6H2SYqoIGvc='
  'sha256-y6gO+OYD2TDPY9r3GtOTvnCs5/oE/O7PmNL2zaGYeJQ='
  'sha256-Jg7eYnts8zlTEJyHuCysngL/qIiJiSEFfkFvZJOMRGY='
  `.trim();
}

export const generateCspHeader = (cspNonce: string): string => {
  const cmsHostname = getCmsHostname();
  const frontendHostname = getFrontendHostname();
  const cspDefault = `
      'self'
      https://${frontendHostname}
      https://${cmsHostname}
      ${process.env.LNDO_SITE ? `https://${process.env.LNDO_SITE}.lndo.site https://cms.${process.env.LNDO_SITE}.lndo.site` : ``}
    `.trim();
  const csp = `
    default-src ${cspDefault};
    object-src 'none';
    base-uri 'self';
    style-src
      ${cspDefault}
      'unsafe-inline'
      https://p.typekit.net
      https://use.typekit.net
      https://tagmanager.google.com
      https://www.googletagmanager.com
      https://fonts.googleapis.com
      https://static.hotjar.com
      https://script.hotjar.com
      https://www.tripadvisor.co.uk
      https://static.tacdn.com
      ;
    font-src
      ${cspDefault}
       data:
       https://p.typekit.net
       https://use.typekit.net
       https://fonts.gstatic.com
       https://fonts.googleapis.com
       https://script.hotjar.com
       https://static.tacdn.com
       ;
    script-src
      ${cspDefault}
      ${process.env.NODE_ENV !== 'production'
        ? `'unsafe-eval' 'unsafe-inline'`
        : `'nonce-${cspNonce}' ${cspScriptHashes()}`}
      https://cdn.jsdelivr.net
      https://connect.facebook.net
      https://cookie-cdn.cookiepro.com
      https://r1.dotdigital-pages.com
      https://r1-t.trackedlink.net/_dmpt.js
      https://s.yimg.com/wi/ytc.js
      https://static.hotjar.com
      https://script.hotjar.com
      https://analytics.twitter.com
      https://static.ads-twitter.com/uwt.js
      https://www.dwin1.com/15333.js
      https://www.google-analytics.com
      https://ssl.google-analytics.com
      https://*.googletagmanager.com
      https://tagmanager.google.com
      https://www.youtube.com
      https://www.youtube-nocookie.com
      https://www.googleadservices.com
      https://googleads.g.doubleclick.net
      https://www.google.com
      https://campaign.zsl.org
      https://reclaimhub.com
      https://www.tripadvisor.co.uk
      https://www.tripadvisor.com
      https://static.tacdn.com
      https://www.jscache.com
      https://analytics.tiktok.com
      https://static.trackedweb.net
      https://www.googletagmanager.com
      https://lantern.roeyecdn.com
      https://go.affec.tv
      https://cdn.cookielaw.org
      https://secure.adnxs.com
      ;
    connect-src
      ${cspDefault}
      https://cookie-cdn.cookiepro.com
      https://s.yimg.com
      https://stats.g.doubleclick.net
      https://*.google.co.uk
      https://*.google.com
      https://*.google-analytics.com
      https://geolocation.onetrust.com
      https://privacyportal.cookiepro.com
      https://*.analytics.google.com
      https://*.googletagmanager.com
      https://pagead2.googlesyndication.com
      https://googleads.g.doubleclick.net
      https://cdn.cookielaw.org
      https://*.hotjar.com
      https://*.hotjar.io
      wss://*.hotjar.com
      https://www.facebook.com/tr/
      https://campaign.zsl.org
      https://analytics.tiktok.com
      https://px.ads.linkedin.com
      ;
    frame-src
      ${cspDefault}
      data:
      https://*.fls.doubleclick.net
      https://vars.hotjar.com
      https://www.facebook.com
      https://www.youtube.com
      https://youtube.com
      https://www.youtube-nocookie.com
      https://r1.dotdigital-pages.com
      https://www.arcgis.com
      https://html5-player.libsyn.com
      https://campaign.zsl.org
      https://www.google.com
      https://bid.g.doubleclick.net
      https://td.doubleclick.net
      https://reclaimhub.com
      https://zsl-london-zoo-timeline.octophindev.com
      https://cdn.knightlab.com
      https://my.matterport.com
      https://hhakkinen.shinyapps.io
      https://registry.blockmarktech.com
      ;
    frame-ancestors
      ${cspDefault}
      https://cms.zsl.org
      https://www.zsl.org
      https://cms.londonzoo.org
      https://www.londonzoo.org
      https://cms.whipsnadezoo.org
      https://www.whipsnadezoo.org
      https://campaign.zsl.org
      ;
    img-src
      ${cspDefault}
      data:
      https://analytics.twitter.com
      https://cookie-cdn.cookiepro.com
      https://sp.analytics.yahoo.com
      https://t.co
      https://www.facebook.com
      https://www.google-analytics.com
      https://ssl.gstatic.com
      https://www.gstatic.com
      https://fonts.gstatic.com
      https://*.google-analytics.com
      https://*.googletagmanager.com
      https://googleads.g.doubleclick.net
      https://www.google.com
      https://www.google.co.uk
      https://www.google.be
      https://campaign.zsl.org
      https://static.hotjar.com
      https://script.hotjar.com
      https://i.ytimg.com
      https://*.fls.doubleclick.net
      https://ad.doubleclick.net
      https://*.googlesyndication.com
      https://www.tripadvisor.co.uk
      https://px.ads.linkedin.com
      https://adservice.google.com
      https://lantern.roeye.com
      https://map.go.affec.tv
      ;
    upgrade-insecure-requests;
  `;

  return csp.replace(/\s{2,}/g, ' ').trim();
}

export const generateFeaturePolicyHeader = (): string => {
  const cmsHostname = getCmsHostname();
  const frontendHostname = getFrontendHostname();
  const featureDefault = `
      'self'
      https://${frontendHostname}
      https://${cmsHostname}
      ${process.env.LNDO_SITE ? `https://${process.env.LNDO_SITE}.lndo.site https://cms.${process.env.LNDO_SITE}.lndo.site` : ``}
    `.trim();
  const featuresPolicy = `
      autoplay ${featureDefault};
      fullscreen *;
      vertical-scroll 'self';
    `;
  return featuresPolicy.replace(/\s{2,}/g, ' ').trim();
}

export const generatePermissionsPolicyHeader = (): string => {
  const cmsHostname = getCmsHostname();
  const frontendHostname = getFrontendHostname();
  const permissionsDefault = `
      self
      "https://${frontendHostname}"
      "https://${cmsHostname}"
      ${process.env.LNDO_SITE ? `"https://${process.env.LNDO_SITE}.lndo.site" "https://cms.${process.env.LNDO_SITE}.lndo.site"` : ``}
    `.trim();
  const permissionsPolicy = `autoplay=(${permissionsDefault}), fullscreen=*`;
  return permissionsPolicy.replace(/\s{2,}/g, ' ').trim();
}
